import { render, staticRenderFns } from "./profileCard.vue?vue&type=template&id=7a68d0e4&scoped=true"
import script from "./profileCard.vue?vue&type=script&lang=js"
export * from "./profileCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a68d0e4",
  null
  
)

export default component.exports