<template>
  <div>
    <div :class="'fileInputWrapper' + ' ' + wrapperStyle">
      <input
        :class="'position-absolute w-100' + ' ' + inputStyle"
        type="file"
        :id="id"
        :ref="id"
        :accept="fileType"
        @change="(event) => handleChange(event)"
        :maxSize="maxSize"
        :sizeErrMsg="sizeErrMsg"
        :extensionErrMsg="extensionErrMsg"
        errMsgStyle=""
      />
    </div>
    <div
      :class="'uploaderErrMsg d-flex justify-content-center w-100 position-absolute' +
        ' ' +
        errMsgWrapperStyle
      "
      v-if="!isEXternalErr"
    >
      <div>
        <p :class="'errMsg mb-0' + ' ' + errMsgStyle" v-if="fileError.sizeErr">
          {{ sizeErrMsg }}
        </p>
        <p
          :class="'errMsg mb-0' + ' ' + errMsgStyle"
          v-if="fileError.extensionErr"
        >
          {{ extensionErrMsg }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import "./fileUploader.scss";
// '.png, .jpeg, png, .svg'
export default {
  props: [
    "id",
    "fileType",
    "maxSize",
    "isImage",
    "formDataKey",
    "sizeErrMsg",
    "extensionErrMsg",
    "inputStyle",
    "wrapperStyle",
    "errMsgStyle",
    "isEXternalErr",
    "errMsgWrapperStyle",
  ],

  data() {
    return {
      fileError: {
        sizeErr: false,
        extensionErr: false,
      },
      imagePreview: "",
    };
  },

  methods: {
    handleFileFormate(file) {
      let formData = new FormData();
      formData.append(this.formDataKey, file);
      formData.append("parse", 0);
      return formData;
    },

    handleImageView(file) {
      let reader = new FileReader();
      reader.onload = (event) => {
        this.imagePreview = event.target.result;
      };
      reader.readAsDataURL(file);
    },

    handleValidation(file) {
      let sizeInMB = (file?.size / 1024) / 1024;
      if (
        sizeInMB > this.maxSize &&
        !this.fileType.includes(file.name.substring( file.name.lastIndexOf('.'), file.name.length))
      ) {
        this.fileError.sizeErr = true;
        this.fileError.extensionErr = true;
        return false;
      } else if (sizeInMB > this.maxSize) {
        this.fileError.sizeErr = true;
        return false;
      } else if (!this.fileType.includes(file.name.substring(file.name.lastIndexOf('.'), file.name.length))) {
        this.fileError.extensionErr = true;
        return false;
      } else {
        this.fileError.sizeErr = false;
        this.fileError.extensionErr = false;
        return true;
      }
    },

    handleChange(event) {
      this.fileError.sizeErr = false;
      this.fileError.extensionErr = false;
      let fileSelected = event.target.files[0];
      if (this.handleValidation(fileSelected)) {
        let fileformate = this.handleFileFormate(fileSelected);
        let fileData = { fileInfo: fileSelected, fileformate, };
        if (this.isImage) {
          this.handleImageView(fileSelected);
          fileData.imagePreview = this.imagePreview;
          this.$emit("fileUploadEvent", fileData);
        } else {
          this.$emit("fileUploadEvent", fileData);
        }
      } else {
        let errorData = { ...this.fileError };
        this.$emit("fileUploadEvent", errorData);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
