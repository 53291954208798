const Languages = [
  "afrikaans",
  "albanian",
  "amharic",
  "arabic",
  "aragonese",
  "armenian",
  "azerbaijani",
  "belarusian",
  "bengali",
  "bosnian",
  "bulgarian",
  "centralKurdish",
  "chinese",
  "croatian",
  "dutch",
  "english",
  "filipino",
  "finnish",
  "french",
  "german",
  "gujarati",
  "hausa",
  "hawaiian",
  "hebrew",
  "hindi",
  "hungarian",
  "indonesian",
  "irish",
  "italian",
  "japanese",
  "kannada",
  "korean",
  "kurdish",
  "kyrgyz",
  "lingala",
  "macedonian",
  "malay",
  "malayalam",
  "marathi",
  "nepali",
  "norwegian",
  "oriya",
  "oromo",
  "pashto",
  "persian",
  "polish",
  "portuguese",
  "punjabi",
  "romanian",
  "russian",
  "serbian",
  "shonaChiShona",
  "sindhi",
  "sinhala",
  "slovenian",
  "somali",
  "southern",
  "spanish",
  "swahili",
  "swedish",
  "tamil",
  "telugu",
  "tigrinya",
  "turkish",
  "turkmen",
  "twi",
  "ukrainian",
  "urdu",
  "uzbek",
  "vietnamese",
  "xhosa",
  "yoruba",
  "zulu",
];

export default Languages;
