<template>
  <div
    class="tab-pane fade show active talent-skills-wrapper"
    id="pills-home"
    role="tabpanel"
    aria-labelledby="pills-home-tab"
  >
    <Bio :bioData="basicInfo.bio" :isLoaded="isLoaded" />
    <div class="position-relative">
      <TabHeader
        :title="$t('signUp.skills')"
        :iconAction="openModal"
        :isEdit="true"
      />

      <div class="skill-tags-wrapper" v-if="basicInfo">
        <div
          v-for="skill in basicInfo.skills"
          :key="skill.id"
          class="skill-tag"
        >
          <span class="skill-name">{{ skill.name }}</span>
          <!--  <span class="skill-experience" v-if="skill.experience!==null &&skill.experience==1">
            {{skill.experience}} Year of experience</span>
          <span class="skill-experience" v-if="skill.experience!==null &&skill.experience!=1">
            {{skill.experience}} Years of experience</span> -->

          <span
            class="skill-experience"
            v-if="skill.years > 0 || skill.months > 0"
          >
            {{ skill.years > 0 ? skill.years + " Y" : "" }}
            <span style="padding: 0" v-if="skill.years > 0 && skill.months > 0">
              &
            </span>
            {{ skill.months > 0 ? skill.months + " M" : "" }}

            of experience
          </span>
        </div>
      </div>

      <Modal
        v-if="isModalOpen"
        @update="isModalOpen = $event"
        :heading="$t('talentWizard.selectSkills')"
        className="skills-modal"
        :handleSubmit="() => handleSubmitSkills()"
        :isSubmitDisabled="this.selectedOptions.length > 0 ? false : true"
        :removeTitleBorder="true"
      >
        <div v-if="searchResult" class="skillsModalContent p-3">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <SearchInput
                 :placeholder="$t('talentWizard.search')"
                :searchInArr="roleSkills"
                @searchInputEvent="handleSearchResult"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 px-0">
              <Checkbox
                v-for="(skill, i) in searchResult"
                :key="i"
                :label="skill.name"
                :inputValue="skill"
                v-model="selectedOptions"
                labelStyle="checkboxLabelStyle"
              />
            </div>
            <div class="col-6 px-0" v-if="selectedOptions">
              <span
                class="skills-label"
                v-for="(option, i) in selectedOptions"
                :key="i"
                >{{ option.name }}
                <button
                  type="button"
                  aria-label="Close"
                  @click="() => deleteSkill(option, i)"
                >
                  <i class="bi bi-x talent-skills-close-icon"></i></button
              ></span>
            </div>
          </div>
        </div>
      </Modal>
      <p class="no-data-text" v-if="basicInfo.skills.length == 0">
        {{ $t("talent.noData") }}
      </p>
    </div>
    <Projects :projects="projects" />
  </div>
</template>

<script>
import "./skillsTab.scss";
import Bio from "../bio/bio.vue";
import Projects from "../projects/projects.vue";
import SearchInput from "../../../../../components/shared/formControls/searchInput/searchInput.vue";
import TabHeader from "../tabHeader/tabHeader.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
import Checkbox from "../../../../../components/shared/formControls/checkbox/checkbox.vue";
export default {
  props: ["basicInfo", "projects", "isLoaded"],
  components: { Projects, Checkbox, TabHeader, Modal, SearchInput, Bio },
  mounted() {
    this.$store
      .dispatch("talentProfile/getRoleSkillsRequest", this.basicInfo?.role?.id)
      .then(() => (this.searchResult = this.roleSkills));
  },
  computed: {
    console: () => console,
    roleSkills() {
      return this.$store.getters["talentProfile/getRoleSkills"];
    },
  },
  data() {
    return {
      skillsList: this.basicInfo.skills,
      isModalOpen: false,
      selectedOptions: [...this.basicInfo.skills],
      searchResult: [],
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.selectedOptions = [...this.basicInfo.skills];
      this.searchResult = this.roleSkills;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    deleteSkill(skill, index) {
      this.selectedOptions?.splice(index, 1);
    },
    handleSearchResult(payload) {
      this.searchResult = payload;
    },
    handleSubmitSkills() {
      let payload = { skills: this.selectedOptions.map((skill) => skill.id) };
      this.$store.dispatch("talentProfile/updateProfileSkills", payload);
      // .then(() => this.$store.dispatch("talentProfile/getAssessmentsRequest", { page: 1 }))

      this.closeModal();
    },
  },
  watch: {
    basicInfo: {
      handler(newVal, oldVal) {
        if (newVal.role.id !== oldVal.role.id) {
          this.$store
            .dispatch(
              "talentProfile/getRoleSkillsRequest",
              this.basicInfo?.role?.id
            )
            .then(() => (this.searchResult = this.roleSkills));
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
