<template>
  <div>
    <v-select
      v-model="$v.year.$model"
      :class="className"
      :placeholder="$t('talentWizard.selectYear')"
      :options="years"
      label="name"
      @input="$emit('input', year)"
      :disabled="!!isDisabled"
    >
       <div slot="no-options">
        {{ $t('talent.sorryNoMatchingOptions')}}
      </div>
    </v-select>
    <!-- ********* Validation Error*********** -->
    <div class="mt-1">
      <VulidateError
        v-show="!isDisabled"
        :validationField="$v.year"
        :params="[]"
      />
    </div>
    <!-- ************************************* -->
  </div>
</template>
<script>
import { requiredIf } from "vuelidate/lib/validators";
import { isWorkable } from "../../../utils/shared";
import VulidateError from "../../shared/VulidateError/vulidateError";
export default {
  components: { VulidateError },
  props: {
    className: {},
    isDisabled: {},
    default: {},
    isRealTimeValidation: {},
    isRequired: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      years: [],
      year: this.default,
    };
  },
  validations: {
    year: {
      required: requiredIf(function () {
        return this.isRequired;
      }),
    },
  },
  created() {
    this.years = this.getYearList();
  },
  mounted() {
    this.isMountedValidation() && this.$v.$touch();
  },
  updated() {
    this.isMountedValidation() && this.$v.$touch();
  },
  methods: {
    isWorkable,
    getYearList() {
      let currentYear = new Date().getFullYear() + 8;
      let years = [];
      for (let i = 0; i <= 50; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    isMountedValidation() {
      if (this.isWorkable() && this.isRealTimeValidation) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isDisabled: function (v) {
      if (v) {
        this.$v.$reset();
        this.year = "";
      }
    },
    default: function () {
      this.year = this.default;
      if (this.default == "") {
        this.$v.$reset();
      }
    },
  },
};
</script>
