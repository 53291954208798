<template>
  <div class="talent-inner-box talent-profile-card-wrapper">
    <!-- <span class="edit-inner-icon" @click="handleEditRedirection"> </span> -->
    <div class="mb-6 d-flex justify-content-between" v-if="!isLoaded"></div>
    <div
      v-if="isLoaded"
      :class="`d-flex  ${
        profile.basicInfo.country
          ? 'justify-content-between'
          : 'justify-content-end'
      }`"
    >
      <div class="country-flag" v-if="isLoaded && profile.basicInfo.country">
        <CountryFlag :countryCode="profile.basicInfo.country.code" />
      </div>
      <span class="edit-icon" @click="handleEditRedirection" v-if="isLoaded">
      </span>
    </div>
    <div class="mt-5 talent-details">
      <h4
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <!-- <span class="pe-2">{{ profile.basicInfo.full_name }}</span> -->

        <span class="pe-2">{{
          truncate(profile.basicInfo.full_name, 12)
        }}</span>

        <!-- <p>\u0001</p>
        <p>&#128512;</p> -->
      </h4>
      <div class="mb-3 d-flex align-items-center justify-content-center">
        <skeleton-loader-vue
          v-if="!isLoaded"
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
          rounded
        />
      </div>

      <span class="mb-2 talent-job d-block" v-if="isLoaded">{{
        profile.basicInfo.role.name
      }}</span>
      <div class="mb-3 d-flex align-items-center justify-content-center">
        <skeleton-loader-vue
          v-if="!isLoaded"
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>

    <div class="circle-bg-color-anim score-circular">
      <div class="circle-bg-color-anim score-inner">
        <skeleton-loader-vue
          v-if="!isLoaded"
          type="circle"
          :width="180"
          :height="180"
          animation="fade"
        />
        <img
          :src="`${baseUrl}${profile.image.path}`"
          class="w-100 profileImg"
          @error="imageLoadOnError"
          v-if="isLoaded && profile.image"
        />
        <img
          src="@/assets/images/talent-avatar.png"
          class="w-100 profileImg"
          v-else
        />
      </div>
      <div class="cameraPhoto" v-if="isLoaded">
        <img
          src="../../../../../assets/images/talent/photo-camera.svg"
          class="cameraImg"
        />
        <FileUploader
          :isImage="true"
          inputStyle=""
          maxSize="1"
          formDataKey="image"
          isEXternalErr="true"
          id="talent-profile-image"
          :fileType="['.jpg', '.jpeg', '.png', '.svg']"
          @fileUploadEvent="handleUploadImg"
          :extensionErrMsg="$t('validation.AllowedExtenstionsAre')"
          :sizeErrMsg="$t('validation.allowedMaximum')"
        />
      </div>
    </div>
    <div>
      <p class="errMsg" v-if="imgUploadData.sizeErr">
        {{ $t("validation.allowedMaximum") }}
      </p>
      <p class="errMsg" v-if="imgUploadData.extensionErr">
        {{ $t("validation.AllowedExtenstionsAre") }}
      </p>
    </div>
    <h5 class="mt-5 regularFont" v-if="isLoaded">
      {{ $t("talent.profileStrength") }}:<span class="bold ps-0">
        {{ profile.completeProfile.rate }}%
      </span>
    </h5>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="200"
        :height="30"
        animation="fade"
        rounded
      />
    </div>
    <hr class="smallHr" />
    <h5
      v-if="isLoaded && profile.basicInfo.BE_lang && !profile.basicInfo.FE_lang"
    >
      {{ profile.basicInfo.BE_lang }}
    </h5>
    <h5
      v-if="isLoaded && profile.basicInfo.FE_lang && !profile.basicInfo.BE_lang"
    >
      {{ profile.basicInfo.FE_lang }}
    </h5>

    <p class="mb-3" v-if="isLoaded && profile.basicInfo.role.id !== 3">
      {{ profile.basicInfo.experience_years }}
      {{
        profile.basicInfo.experience_years == 1
          ? $t("signUp.yearOfExperience")
          : $t("signUp.yearsOfExperience")
      }}
    </p>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="200"
        :height="30"
        animation="fade"
        rounded
      />
    </div>

    <div
      class="text-center full-exp d-flex justify-content-around"
      v-if="isLoaded && profile.basicInfo.role.id == 3"
    >
      <div>
        <h5>{{ profile.basicInfo.BE_lang }}</h5>
        <p class="mb-0 talent-experience">
          {{ profile.basicInfo.experience_years }}
          {{
            profile.basicInfo.experience_years == 1
              ? $t("signUp.yearOfExperience")
              : $t("signUp.yearsOfExperience")
          }}
        </p>
      </div>
      <div>
        <h5>{{ profile.basicInfo.FE_lang }}</h5>
        <p class="mb-0 talent-experience">
          {{ profile.basicInfo.second_experience_years }}
          {{
            profile.basicInfo.second_experience_years == 1
              ? $t("signUp.yearOfExperience")
              : $t("signUp.yearsOfExperience")
          }}
        </p>
      </div>
    </div>

    <hr class="smallHr" />

    <Availability
      v-if="isLoaded"
      :full_time_status="profile.basicInfo.full_time_status"
      :part_time_status="profile.basicInfo.part_time_status"
      :part_time_availability="profile.basicInfo.part_time_availability"
      :allow_edit_availability="profile.basicInfo.allow_edit_availability"
    />
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="360"
        :height="30"
        animation="fade"
        rounded
      />
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="360"
        :height="30"
        animation="fade"
        rounded
      />
    </div>

    <hr class="smallHr" />
    <Salary
      v-if="isLoaded"
      :full_time_salary="profile.basicInfo.salary"
      :part_time_salary="profile.basicInfo.part_time_salary"
    />
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="360"
        :height="30"
        animation="fade"
        rounded
      />
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="360"
        :height="30"
        animation="fade"
        rounded
      />
    </div>
    <hr class="smallHr" />
    <span v-if="isLoaded && profile.accounts.length !== 0">
      <ul class="socialLinks">
        <li v-for="account in profile.accounts" :key="account.id">
          <a :href="getAccount(account.url)" target="_blank">
            <img
              :src="
                require('../../../../../assets/images/' + links[account.type])
              "
            />
          </a>
        </li>
      </ul>

      <hr class="smallHr" />
    </span>

    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue
        v-if="!isLoaded"
        type="rect"
        :width="360"
        :height="30"
        animation="fade"
        rounded
      />
    </div>

    <a
      target="_blank"
      :href="`${baseUrl}${profile.cv.path}`"
      :download="profile.cv.name"
      class="text-start align-items-center d-flex cv-Box download-cv"
      v-if="profile.cv && isLoaded"
    >
      <img src="../../../../../assets/images/ic_pdf.svg" class="me-3" />
      <div class="mt-1 cvName">{{ profile.cv.name }}<br /></div>
    </a>

    <a class="mt-4 btn btn-outline-blue d-block" v-if="isLoaded">
      <div
        class="position-relative justify-content-center d-flex align-items-center"
      >
        <img src="../../../../../assets/images/ic_upload.svg" class="me-2" />
        <span v-if="profile.cv">
          {{ $t("talent.reUploadCv") }}
        </span>
        <span v-if="!profile.cv">
          {{ $t("talent.uploadCv") }}
        </span>
        <FileUploader
          formDataKey="cv"
          inputStyle="height-40"
          id="talent-profile-cv"
          maxSize="4.8"
          fileType=".csv, .pdf, .doc, .docx, .txt"
          @fileUploadEvent="handleUploadCv"
          :extensionErrMsg="$t('validation.AllowedExtenstionsAre')"
          :sizeErrMsg="$t('validation.allowedMaximum')"
          errMsgWrapperStyle="cvErrMsgWrapperStyle"
        />
      </div>
    </a>
  </div>
</template>

<script>
import FileUploader from "../../../../../components/shared/formControls/fileUploader/fileUploader.vue";
import CountryFlag from "../../../../../components/shared/countryFalg/countryFalg.vue";
import Availability from "../availability/availability.vue";
import levels from "../../../../../static/LanguageLevel";
import ACCOUNTS from "../../../../../enums/UserAccounts";
import { getUrl } from "../../../../../functions/url";
import { truncate } from "../../../../../utils/utils";
import Salary from "../salary/salary.vue";

import "./profileCard.scss";
export default {
  props: ["profile", "isLoaded"],
  components: { Availability, Salary, FileUploader, CountryFlag },
  data: () => {
    return {
      imgUploadData: {
        sizeErr: false,
        extensionErr: false,
      },
      levels: [],
      links: {
        [ACCOUNTS.LINKED_IN]: "ic_linkedin.svg",
        [ACCOUNTS.GITHUB]: "ic_github.svg",
        [ACCOUNTS.BEHANCE]: "ic_behance.svg",
        [ACCOUNTS.DRIBBBLE]: "ic_dribbble.svg",
        [ACCOUNTS.WEBSITE]: "ic_website-icon.svg",
      },
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      fileValue: "",
    };
  },
  mounted() {
    this.levels = levels.map((level) => ({
      ...level,
      name: this.$t(`languageLevel.${[level.name]}`),
    }));
  },
  computed: {},
  methods: {
    truncate,
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    getLevel(id) {
      const filteredLevel = this.levels.filter((level) => level.id == id);
      return filteredLevel[0].name;
    },
    getAccount(account) {
      return getUrl(account);
    },
    handleUploadCv(payload) {
      if (payload?.fileformate) {
        this.$store.dispatch(
          "talentProfile/uploadProfileCV",
          payload.fileformate
        );
      }
    },
    handleUploadImg(payload) {
      if (payload?.fileformate) {
        this.imgUploadData = {
          sizeErr: false,
          extensionErr: false,
        };
        this.$store.dispatch(
          "talentProfile/uploadProfileImage",
          payload.fileformate
        );
      } else {
        this.imgUploadData = { ...payload };
      }
    },
    handleEditRedirection() {
      this.$router.push({ path: "/sq/talent/editProfile" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
