var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[(_vm.contentArr.length)?_c('div',{class:`container ${_vm.arrowStyle}`},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[(_vm.contentArr.length > 1)?_c('carousel',{staticClass:"matching-skills-carousel",attrs:{"items":1,"loop":false,"margin":10,"rewind":_vm.rewind,"nav":true,"dots":false,"mouseDrag":false,"responsive":{
            0: {
              items: 1,
            },
            768: {
              items: 1,
            },
          }},on:{"changed":_vm.passEvent}},_vm._l((_vm.contentArr),function(item,i){return _c(_vm.content,{key:i,tag:"component",attrs:{"item":item,"list":_vm.contentArr},on:{"change":_vm.passEvent}})}),1):_c('div',{staticClass:"matching-skills-carousel"},_vm._l((_vm.contentArr),function(item,i){return _c(_vm.content,{key:i,tag:"component",attrs:{"item":item,"list":_vm.contentArr},on:{"change":_vm.passEvent}})}),1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }