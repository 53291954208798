<template>
  <div>
    <v-select
      v-model="$v.month.$model"
      :reduce="(name) => name.id"
      :class="className"
      :placeholder="$t('talentWizard.selectMonth')"
      :options="monthsLocationztion"
      label="name"
      @input="$emit('input', month)"
      :disabled="!!isDisabled"
    >
      <div slot="no-options">
        {{ $t('talent.sorryNoMatchingOptions')}}
      </div>
    </v-select>
    <!-- ********* Validation Error*********** -->
    <div class="mt-1">
      <VulidateError
        v-show="!isDisabled"
        :validationField="$v.month"
        :params="[]"
      />
    </div>
    <!-- ************************************* -->
  </div>
</template>
<script>
import { requiredIf } from "vuelidate/lib/validators";
import "vue-select/dist/vue-select.css";
import Months from "../../../static/Months";
import VulidateError from "../VulidateError/vulidateError.vue";
import { isWorkable } from "../../../utils/shared";
export default {
  components: { VulidateError },
  props: {
    className: {},
    isDisabled: {},
    default: {},
    isRealTimeValidation: {},
    isRequired: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    monthsLocationztion() {
      return this.Months.map(month => ( {...month, name: this.$t(`months.${[month.name]}`)  })) 
    }
  },
  data() {
    return {
      Months: Months,
      month: this.default,
    };
  },
  validations: {
    month: {
      required: requiredIf(function () {
        return this.isRequired;
      }),
    },
  },
  mounted() {
    this.isMountedValidation() && this.$v.$touch();
  },
  updated() {
    this.isMountedValidation() && this.$v.$touch();
  },
  watch: {
    isDisabled: function (v) {
      if (v) {
        this.$v.$reset();
        this.month = "";
      }
    },
    default: function () {
      this.month = this.default;
      if (this.default == "") {
        this.$v.$reset();
      }
    },
  },
  methods: {
    isWorkable,
    isMountedValidation() {
      if (this.isWorkable() && this.isRealTimeValidation) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
