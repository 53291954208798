<template>
  <div
    class="assessmentContentWrapper row align-items-center"
    v-if="
      item.assessment.percentage != null &&
      item.assessment.provider == 'hackerearth'
    "
  >
    <!-- <div v-if="!isSkeleton" class="col-12"> -->
    <div class="col-12">
      <!-- take assessment -->
      <div
        class="row justify-content-between align-items-center p-2"
        v-if="item.assessment.percentage === null && false"
      >
        <div class="col-12 col-xl-9 d-flex align-items-center">
          <div class="d-flex align-items-baseline justify-content-center">
            <img
              src="../../../../../assets/images/ic_Assessment_report.svg"
              class="takeAssessmentImg"
            />
          </div>
          <div class="mx-3">
            <p class="assessmentTitle mb-2">
              {{ handleAssessmentName() }} Assessment
            </p>
            <p class="assessmentSubtitle mb-0">
              {{
                list.length > 1
                  ? "You have other assessments, swipe right or left to check their status"
                  : "Passing the assessment will qualify your Role or Skill"
              }}
            </p>
          </div>
        </div>
        <div
          class="col-xl-3 col-12 d-flex justify-content-center justify-content-xl-end mt-xl-0 mt-3 invitaionlink"
        >
          <a
            :href="item.assessment.link"
            target="_blank"
            id="assessmentInvitationLink"
          >
            <Button
              text="Take assessment"
              varient="btnPrimary"
              :action="() => handleTakeAssessment()"
            />
          </a>
        </div>
      </div>

      <!-- re-take assessment -->
      <!-- <div class="row justify-content-between align-items-center p-2" v-if="item.status.id === 2">
      <div class="col-12 col-xl-6">
       <div class="d-flex align-items-baseline">
            <p class="assessmentTitle">{{ item.skill }} Assessment</p>
        <p class="totalScore px-1">
        <span class="score">85</span><span class="total px-1">/100</span><span class="totalScoreTextRetake">Total score</span>
        </p> 
       </div>
         <p class="assessmentSubtitle">
          You have other skills that need to be verified, swip right to see more
        </p>
      </div>
      <div class="col-xl-6 col-12 d-flex justify-content-center justify-content-xl-end">
         <Button
          text="view report"
          varient="btnOutlinePrimary"
          :action="() => handleViewReport()"
          btnStyle="mx-1"
        />
        <Button
          text="re-taking"
          varient="btnPrimary"
          :action="() => handleRetakeAssessment()"
        />
      </div>
    </div> -->

      <!-- pass or fail assessment -->
      <div class="row justify-content-between align-items-center p-2">
        <div class="col-12 col-xl-8">
          <p class="assessmentTitle mb-0 contentCenter">
            {{ handleAssessmentName() }} Assessment
            <span
              :class="item.assessment.percentage >= 50 ? 'passed' : 'failed'"
              >{{
                item.assessment.percentage >= 50 ? "(Passed)" : "(Failed)"
              }}</span
            >
          </p>
          <p class="totalScore mb-0 contentCenter">
            <span class="score">{{ item.assessment.percentage }}</span
            ><span class="total px-1">/100</span
            ><span class="totalScoreText">Total score</span>
          </p>
          <p class="font-size-12 textLeft contentCenter mb-0">
            {{
              list.length > 1
                ? "You have other assessments, swipe right or left to check their status"
                : "Passing the assessment will qualify your Role or Skill"
            }}
          </p>
        </div>
        <!-- <div
        class="col-xl-4 col-12 d-flex justify-content-center justify-content-xl-end"
      >
        <Button
          text="View Report"
          varient="btnPrimary"
          :action="() => handleViewReport()"
        />
      </div> -->
      </div>
    </div>
    <!-- <div v-if="isSkeleton" class="col-12">
       <Skeleton />
     </div> -->
  </div>
</template>
<script>
import Button from "../../../../../components/shared/button/button.vue";
// import Skeleton from "./carouselContentSkeleton.vue";

import "./assessments.scss";

export default {
  components: {
    Button,
    // Skeleton
  },
  props: ["item", "list"],
  data() {
    return {};
  },
  computed: {
    console: () => console,
    isSkeleton() {
      return this.$store.getters["shared/getSkeletonStaus"];
    },
  },
  methods: {
    handleTakeAssessment() {
      if (this.item.assessment.link === null) {
        this.$store.dispatch("talentProfile/getAssessmentInvitationRequest", {
          AssessmentId: this.item.assessment.id,
        });
      }
    },
    handleAssessmentName() {
      let assessmentName = "";
      if (this.item.assessment.forRole) {
        if (
          this.item.assessment.forRole.name ===
          this.item.assessment.tech_stack[0]
        ) {
          assessmentName = `${this.item.assessment.forRole.name} "V${this.item.assessment.version}" `;
          return assessmentName;
        } else {
          assessmentName = `${
            this.item.assessment.forRole.name
          } ( ${this.item.assessment.tech_stack?.join(",")} ) "V${
            this.item.assessment.version
          }" `;
          return assessmentName;
        }
      } else {
        assessmentName = `${this.item.name} "V${this.item.assessment.version}" `;
        return assessmentName;
      }
    },
    handleViewReport() {
      window.open(this.item.assessment.report, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped></style>
