<template>
  <svg
    viewBox="0 0 16 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- <title>ic_edit-large</title> -->
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        id="01.26.profile-tab-2"
        transform="translate(-565.000000, -161.000000)"
        stroke="#422E87"
      >
        <g id="profile-side/1" transform="translate(280.000000, 129.000000)">
          <g
            id="btn-icon/ic_edit-large"
            transform="translate(273.000000, 21.000000)"
          >
            <g id="ic_edit-large" transform="translate(13.000000, 12.000000)">
              <path
                d="M8.55555556,0 L11.6666667,3.12195122 L3.11111111,11.7073171 L0,11.7073171 L0,8.58536585 L8.55555556,0 L8.55555556,0 Z M0,15.6097561 L14,15.6097561"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>
