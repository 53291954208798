<template>
  <div class="bio-wrapper">
    <TabHeader
      :title="$t('signUp.bio')"
      :isEdit="view == 'edit' ? true : false"
      :hideAddIcon="true"
      :iconAction="() => handleModalView(true)"
    />
    <p v-if="view === 'edit'" class="bioText pb-3">{{ bioData }}</p>
    <div class="row add-bio-wrapper" v-if="view === 'add'">
      <div
        class="col-2 addIcon d-flex align-items-center justify-content-center"
        @click="() => handleModalView(true)"
      >
        <img src="../../../../../assets/images/Ic_add-fill_1.svg" />
      </div>
      <div class="col-10">
        <p class="title mb-0">
          {{ $t("signUp.addBio") }}
        </p>
        <p class="subTitle">
          {{ $t("signUp.addBioText") }}
        </p>
      </div>
    </div>

    <Modal
      v-if="modalIsOpen"
      @update="modalIsOpen = $event"
      :heading="bioData === null ? $t('signUp.addBio') : $t('talentWizard.editBio')"
      :descreption="$t('signUp.addBioText')"
      className="bio-modal"
      :handleSubmit="() => handleSubmitBio()"
      :isSubmitDisabled="$v.bio.$invalid"
      :hideCloseIcon="true"
    >
      <div>
        <Textarea
          :validationField="$v.bio"
          :placeholder="$t('talentWizard.enterBio')"
          :label="$t('talentWizard.addBio')"
          rows="8"
          :params="[{ maxLength: { number: '1000' } }]"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import "./bio.scss";
import TabHeader from "../tabHeader/tabHeader.vue";
import { maxLength } from "vuelidate/lib/validators";
import Textarea from "../../../../../components/shared/formControls/textarea/textarea.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
export default {
  props: ["bioData"],
  components: { TabHeader, Modal, Textarea },
  mounted() {
    this.handleView();
  },
  data() {
    return {
      view: "",
      bio: this.bioData,
      modalIsOpen: false,
    };
  },
  computed: {
    console: () => console,
  },
  validations: {
    bio: {
      maxLength: maxLength(1000),
    },
  },
  methods: {
    handleView() {
      if (this.bioData !== null) {
        this.view = "edit";
      } else {
        this.view = "add";
      }
    },
    handleSubmitBio() {
      let payload = {
        bio: this.bio,
      };
      this.$store.dispatch("talentProfile/updateBioRequest", payload);
      this.handleModalView(false);
    },
    handleModalView(status) {
      (this.bio = this.bioData), (this.modalIsOpen = status);
    },
  },

  watch: {
    bioData: {
      handler(newVal, oldVal) {
        this.console.log(newVal, oldVal);
        this.handleView();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
