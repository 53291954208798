import moment from "moment";

const validateStartDate = (startMonth, startYear, endMonth, endYear) => {
    let startDateError = "";
    let futureDateError = "";
    if (startMonth && startYear) {
        let startDate = getFullDate(startYear, startMonth);
        futureDateError = checkFutureDate(startDate);
        if (endMonth && endYear) {
            let endDate = getFullDate(endYear, endMonth);
            if (startDate.isAfter(endDate)) {
                startDateError = 'startEndDates';
            }
        }
    }
    return{
            startDateError: startDateError,
            endDateError: "",
            futureDateError: futureDateError
        };
};

const checkFutureDate = startDate => {
    let futureDateError = "";
    if (startDate.isAfter(moment())) {
        futureDateError = 'futureDate';
    }
    return futureDateError;
};

const validateEndDate = (startMonth, startYear, endMonth, endYear) => {
    let endDateError = "";
    if (startMonth && startYear && endMonth && endYear) {
        let startDate = getFullDate(startYear, startMonth);
        let endDate = getFullDate(endYear, endMonth);
        if (startDate.isAfter(endDate)) {
            endDateError = 'endEndDates'
        }
    }
    return {
            startDateError: "",
            endDateError: endDateError
        };
};

const getFullDate = (year, month) => {
    return moment(year + "-" + month + "-01");
};

export {
    validateStartDate,
    checkFutureDate,
    getFullDate,
    validateEndDate
};
