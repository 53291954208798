<template>
  <div :class="`shared-checkbox ${wrapperStyle}`">
    <label :class="`control control--checkbox ${labelStyle}`"
      >{{ label }}
      <span v-if="required" class="astrik">*</span>
      <input type="checkbox" :value="inputValue" v-model="model" />

      <div class="control__indicator"></div>
    </label>
    <p v-if="required">This field is required</p>
  </div>
</template>

<script>
import "./checkbox.scss";
export default {
  props: [
    "required",
    "wrapperStyle",
    "label",
    "value",
    "inputValue",
    "labelStyle",
  ],
  computed: {
    model: {
      get() {
        return this.value.find((skill) => skill.id == this.inputValue.id);
      },
      set(value) {
        if (value) {
          let newValue = [...this.value, this.inputValue];
          this.$emit("input", newValue);
        }else {
          let newValue = this.value.filter(skill => skill.id !== this.inputValue.id)
          this.$emit("input", newValue);
        }
      },
    },
  },
};
</script>