var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"availability-wrapper",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"row time-skill font-size-14 mb-2 align-items-center justify-content-between"},[_c('div',{staticClass:"col-4 text-start padding-r"},[_c('h4',{staticClass:"mb-0 font-size-14 padding-r"},[_vm._v(_vm._s(_vm.$t("signUp.fullTime")))])]),_c('div',{staticClass:"col-8 text-end text-grey d-flex justify-content-end px-0"},[(_vm.is_edit.fullTime)?_c('div',{staticClass:"col-12 px-0 d-flex align-items-center"},[_c('Select',{attrs:{"inputStyle":"font-size-14","placeholder":_vm.$t('talent.selectAvailability'),"validationField":_vm.$v.availability.full_time_status,"selectOptions":_vm.availabilityOptionsLocaliztion,"hideLabel":"true"}}),_c('p',{staticClass:"ok-button mb-0",on:{"click":(e) =>
              _vm.handleValueSelected(e, 'fullTime', false, 'full_time_status')}},[_vm._v(" "+_vm._s(_vm.$t('talent.ok'))+" ")])],1):_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(_vm.full_time_status !== null)?_c('p',{staticClass:"mb-0 font-size-14"},[_vm._v(" "+_vm._s(_vm.getAvailabilityTimeName(_vm.full_time_status))+" ")]):_c('p',{staticClass:"mb-0 font-size-14"},[_vm._v(" "+_vm._s(_vm.$t('talent.notSelected'))+" ")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('talent.youAlreadyAssignedToFullTimeContract'),
            visible: !_vm.allow_edit_availability.allow_full_time,
          }),expression:"{\n            content: $t('talent.youAlreadyAssignedToFullTimeContract'),\n            visible: !allow_edit_availability.allow_full_time,\n          }"}],staticClass:"edit-icon",on:{"click":(e) =>
              _vm.allow_edit_availability.allow_full_time
                ? _vm.handleEditView(e, 'fullTime', true)
                : false}},[_c('EditIcon')],1)])])]),_c('div',{staticClass:"row time-skill mb-2 align-items-center justify-content-between"},[_c('div',{staticClass:"col-4 padding-r text-start"},[_c('h4',{staticClass:"mb-0 font-size-14 padding-r"},[_vm._v(_vm._s(_vm.$t("signUp.partTime")))])]),_c('div',{staticClass:"col-8 text-end text-grey d-flex justify-content-end px-0"},[(_vm.is_edit.partTime)?_c('div',{staticClass:"px-0 col-12 d-flex align-items-center"},[_c('Select',{attrs:{"placeholder":_vm.$t('talent.selectAvailability'),"validationField":_vm.$v.availability.part_time_status,"selectOptions":_vm.availabilityOptionsLocaliztion,"hideLabel":"true"}}),_c('p',{staticClass:"ok-button mb-0",on:{"click":(e) =>
              _vm.handleValueSelected(e, 'partTime', false, 'part_time_status')}},[_vm._v(" "+_vm._s(_vm.$t('talent.ok'))+" ")])],1):_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(_vm.part_time_availability)?_c('div',[(_vm.part_time_status !== null)?_c('p',{staticClass:"mb-0 font-size-14"},[_vm._v(" "+_vm._s(_vm.getAvailabilityTimeName(_vm.part_time_status))+" ")]):_c('p',{staticClass:"mb-0 font-size-14"},[_vm._v(" "+_vm._s(_vm.$t('talent.notSelected'))+" ")])]):_c('p',{staticClass:"mb-0 font-size-14"},[_vm._v(" "+_vm._s(_vm.$t('availabilityOptionsTalantProfile.unavailable'))+" ")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('talent.youAlreadyAssignedToPartTimeContract'),
              visible: !_vm.allow_edit_availability.allow_part_time,
            }),expression:"{\n              content: $t('talent.youAlreadyAssignedToPartTimeContract'),\n              visible: !allow_edit_availability.allow_part_time,\n            }"}],staticClass:"edit-icon",on:{"click":(e) =>
                _vm.allow_edit_availability.allow_part_time
                  ? _vm.handleEditView(e, 'partTime', true)
                  : false}},[_c('EditIcon')],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }