<template>
  <div>
    <!-- take assessment skeleton -->
    <!-- <div class="row justify-content-between align-items-center p-2 loading-skeleton">
      <div class="col-12 col-xl-6">
        <p>------</p>
        <p>------</p>
      </div>
      <div class="col-xl-4 col-12 d-flex justify-content-center justify-content-xl-end">
      <p class="w-100">----</p>
      </div>
    </div> -->
    <div class="spinner-border text-warning" role="status"></div>
    <p class="loadingText">
      {{ $t("signUp.weAreLoadingTheAssessmentPleaseWait") }}
    </p>
  </div>
</template>
<script>
import "./assessments.scss";

export default {};
</script>
<style 
Buttonlang="scss" scoped></style>