<template>
  <div v-if="isLoaded">
    <ul
      class="nav nav-pills SkillTab profile-tabs-wrapper"
      id="pills-tab"
      role="tablist"
    >
      <li
        class="nav-item col"
        role="presentation"
        v-for="tab in tabs"
        :key="tab.id"
      >
        <button
          class="nav-link"
          :class="tab.id == 'pills-home-tab' && 'active'"
          :id="tab.id"
          data-bs-toggle="pill"
          :data-bs-target="`#${tab.target}`"
          type="button"
          role="tab"
          :aria-controls="tab.target"
          aria-selected="true"
        >
          <div class="item">
            <div class="text-center skill-box">
              <div class="skillIcon">
                <img
                  :src="
                    require(`../../../../../assets/images/tabs/${tab.icon}.svg`)
                  "
                />
              </div>
              <div class="skilText">
                {{ $t(`signUp.${tab.name}`) }}
              </div>
            </div>
          </div>
        </button>
      </li>
    </ul>

    <div v-if="!isLoaded" class="nav nav-pills SkillTab profile-tabs-wrapper">
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-for="loader in 3"
        :key="loader"
      >
        <skeleton-loader-vue
          type="rect"
          :width="800"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./tabs.scss";
export default {
  components: {},
  props: ["isLoaded"],
  data: () => {
    return {
      tabs: [],
    };
  },
  mounted() {
    this.getTabsData();
  },
  methods: {
    getTabsData() {
      this.tabs = [
        {
          name: "skillsAndProjects",
          id: "pills-home-tab",
          target: "pills-home",
          icon: "ic_skills",
        },

        {
          name: "experience",
          id: "pills-Experience-tab",
          target: "pills-Experience",
          icon: "ic_icon-exp",
        },

        {
          name: "education",
          id: "pills-Education-tab",
          target: "pills-Education",
          icon: "ic_icon-edu",
        },

        {
          name: "certificates",
          id: "pills-Certificates-tab",
          target: "pills-Certificates",
          icon: "ic_icon-cer",
        },
        {
          name: "language",
          id: "pills-languages-tab",
          target: "pills-languages",
          icon: "ic_languagee",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
