import { render, staticRenderFns } from "./educationTab.vue?vue&type=template&id=52461256&scoped=true"
import script from "./educationTab.vue?vue&type=script&lang=js"
export * from "./educationTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52461256",
  null
  
)

export default component.exports