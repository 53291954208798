var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-form"},[_vm._t("education-form",function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-4"},[_c('Input',{attrs:{"label":`${_vm.$t('talentWizard.school')} / ${_vm.$t('talentWizard.university')}`,"placeholder":`${_vm.$t('talentWizard.school')} / ${_vm.$t('talentWizard.university')}`,"validationField":_vm.$v.education.university,"params":[
            'required',
            'alphaNumericWithSpecialChars',
            { maxLength: { number: '50' } },
          ],"isAstrik":"true"}})],1),_c('div',{staticClass:"col-lg-6 col-12 mb-4"},[_c('Input',{attrs:{"label":_vm.$t('talentWizard.degree'),"placeholder":_vm.$t('talentWizard.degree'),"validationField":_vm.$v.education.degree,"params":[
            'alphaNumericWithSpecialChars',
            { maxLength: { number: '50' } },
          ]}})],1),_c('div',{staticClass:"col-lg-6 col-12 mb-4"},[_c('Input',{attrs:{"label":_vm.$t('talentWizard.fieldOfStudy'),"placeholder":_vm.$t('talentWizard.fieldOfStudy'),"validationField":_vm.$v.education.study_field,"params":[
            'required',
            'alphaNumericWithSpecialChars',
            { maxLength: { number: '50' } },
          ],"isAstrik":"true"}})],1),_c('div',{staticClass:"col-12 mb-0 mb-md-4"},[_c('OneCheckBoxInput',{attrs:{"ClassLabel":'control control--checkbox',"labelText":_vm.$t('talentWizard.iAmUndergraduate'),"checked":_vm.education.currently},on:{"input":_vm.inputChecked}})],1),_c('div',{staticClass:"col-sm-6 col-12 mb-0 mb-md-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label required"},[_vm._v(" "+_vm._s(_vm.$t('talentWizard.startDate'))+" ")])]),_c('div',{staticClass:"col-sm-6 col-12 mb-sm-0 mb-3"},[_c('MonthsDropDown',{attrs:{"className":'style-chooser',"default":_vm.education.start_month},on:{"input":_vm.setStartMonth}})],1),_c('div',{staticClass:"col-sm-6 col-12 mb-sm-0 mb-3"},[_c('YearsDropDown',{attrs:{"className":'style-chooser',"default":_vm.education.start_year},on:{"input":_vm.setStartYear}})],1)]),_c('div',{staticClass:"row"},[_c('span',{staticStyle:{"color":"#ff6f59"}},[_vm._v(_vm._s(_vm.futureDateError))]),(!_vm.futureDateError)?_c('span',{staticStyle:{"color":"#ff6f59"}},[_vm._v(_vm._s(_vm.startDateError))]):_vm._e()])]),_c('div',{staticClass:"col-sm-6 col-12 mb-0 mb-md-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label required"},[_vm._v(" "+_vm._s(_vm.$t('talentWizard.endDate'))+" ")])]),_c('div',{staticClass:"col-sm-6 col-12 mb-sm-0 mb-3"},[_c('MonthsDropDown',{attrs:{"className":'style-chooser',"isDisabled":_vm.education.currently,"default":_vm.education.end_month},on:{"input":_vm.setEndMonth}})],1),_c('div',{staticClass:"col-sm-6 col-12 mb-sm-0 mb-3"},[_c('YearsDropDown',{attrs:{"className":'style-chooser',"isDisabled":_vm.education.currently,"default":_vm.education.end_year},on:{"input":_vm.setEndYear}})],1)]),_c('div',{staticClass:"row"},[_c('span',{staticStyle:{"color":"#ff6f59"}},[_vm._v(_vm._s(_vm.endDateError))])])]),_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.validForm()))])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }