<template>
  <div :class="wrapperStyle" class="textarea-Wrapper">
    <label class="mb-1" :class="isAstrik ? 'form-label required' : ''" v-if="label">
      {{ label }}
    </label>
    <textarea
      class="form-control textArea"
      :placeholder="placeholder"
      :class="className"
      v-model.trim="validationField.$model"
      :disabled="isDisabled"
      @input="(e) => handleInputChange(e)"
      :rows="rows"
    >
    </textarea>
    <VulidateError :validationField="validationField" :params="params" :errorMsg="errorMsg" :errMsgStyle="errMsgStyle" v-if="!isExternalErrMsg"/>
  </div>
</template>

<script>
import VulidateError from "../../VulidateError/vulidateError.vue";
import "./textarea.scss";
export default {
  components: { VulidateError },
  props: [
    "wrapperStyle",
    "label",
    "className",
    "placeholder",
    "params",
    "validationField",
    "isDisabled",
    "errorMsg",
    "isAstrik",
    "rows",
    "errMsgStyle",
    "isExternalErrMsg"
  ],
  methods: {
    handleInputChange(e) {
      this.$emit("handleChange", e);
    },
  },
};
</script>
