<template>
  <div class="talent-profile__wrapper">
    <div class="container text-center">
      <div class="row" ref="">
        <div class="text-center col-md-12 col-lg-4">
          <ProfileCard :profile="profile" :isLoaded="isLoaded" />
        </div>
        <div class="mb-5 col-md-12 col-lg-8">
          <Assessments :isLoaded="isLoaded" :class="{ 'mb-3': !isLoaded }" />
          <div class="align-items-center">
            <Tabs :isLoaded="isLoaded" />
            <div
              class="tab-content skills-tab-content skills-tab-content-view"
              id="pills-tabContent"
            >
              <div v-if="!isLoaded">
                <div
                  class="my-3 d-flex align-items-center justify-content-center"
                  v-for="loader in 6"
                  :key="loader"
                >
                  <skeleton-loader-vue
                    type="rect"
                    :width="800"
                    :height="30"
                    animation="fade"
                    rounded
                  />
                </div>
              </div>

              <div v-if="isLoaded">
                <SkillsTab
                  :basicInfo="profile.basicInfo"
                  :projects="profile.projects"
                />
                <ExperiencesTab :experiences="profile.experiences" />
                <EducationTab :educations="profile.educations" />
                <CertificatesTab :certificates="profile.certifications" />
                <LanguagesTab
                  v-if="isLoaded"
                  :languages="profile.basicInfo.languages"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Steps from "../../../enums/WizzardSteps";
import { scrollToTop } from "../../../utils/utils";
import CertificatesTab from "./components/certificatesTab/certificatesTab.vue";
import ExperiencesTab from "./components/experiencesTab/experiencesTab.vue";
import EducationTab from "./components/educationTab/educationTab.vue";
import LanguagesTab from "./components/languagesTab/languagesTab.vue";
import Assessments from "./components/assessments/assessments.vue";
import ProfileCard from "./components/profileCard/profileCard.vue";
import SkillsTab from "./components/skillsTab/skillsTab.vue";
import Levels from "../../../static/LanguageLevel";
import Tabs from "./components/tabs/tabs.vue";
import "./profile.scss";

export default {
  components: {
    ProfileCard,
    Tabs,
    SkillsTab,
    ExperiencesTab,
    EducationTab,
    CertificatesTab,
    LanguagesTab,
    Assessments,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    this.getProfileDataRequest();
    this.scrollToTop();
    this.isProfileCompleted();
  },
  destroyed() {
    this.$store.dispatch("talentProfile/getAssessmentsResponse", []);
  },
  computed: {
    profile() {
      return this.$store.getters["talentProfile/getProfileData"];
    },
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    scrollToTop,
    async getProfileDataRequest() {
      console.log('getProfileDataRequest');
      this.$store.dispatch("talentProfile/getProfileDataRequest").then(() => {
        this.isLoaded = true;
       
        window.gtag("event", "applicant profile complete", {
          user_type: 'Talent',
          step_number: 'Step 8',
          step_name: 'Talent Profile',
          first_name: this.user?.first_name,
          last_name: this.user?.last_name,
          phone_number: this.user?.phone_number,
          country: this.profile.basicInfo.country?.name,
          email: this.user?.email,
        })
      });
    },
    getLanguageLevel(id) {
      if (id) {
        return Levels.map(level => ({...level , name: this.$t(`languageLevel.${[level.name]}`)  })).find((element) => element.id === id).name;
      }
    },
    checkLengthOfLanguages() {
      // return this.languages.length > 1;
    },
    isProfileCompleted() {
      let step = JSON.parse(localStorage.getItem("step"));
      return step == Steps.PROFILE
        ? null
        : this.$router.push({ name: "Wizard" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
