<template>
  <div class="mb-2">
    <div :class="`container ${arrowStyle}`" v-if="contentArr.length">
      <div class="row align-items-center">
        <div class="col-md-12">
          <carousel
            v-if="contentArr.length > 1"
            @changed="passEvent"
            :items="1"
            :loop="false"
            :margin="10"
            :rewind="rewind"
            :nav="true"
            :dots="false"
            :mouseDrag="false"
            :responsive="{
              0: {
                items: 1,
              },
              768: {
                items: 1,
              },
            }"
            class="matching-skills-carousel"
          >
            <component
              :is="content"
              v-for="(item, i) in contentArr"
              :key="i"
              :item="item"
              :list="contentArr"
              @change="passEvent"
            ></component>
          </carousel>
          <div  v-else  class="matching-skills-carousel">
           <component
              :is="content"
              v-for="(item, i) in contentArr"
              :key="i"
              :item="item"
              :list="contentArr"
              @change="passEvent"
            ></component>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import "./carousel.scss";
export default {
  components: { carousel },
  props: ["content", "contentArr", "rewind", "arrowStyle"],
  computed: {
    console: () => console,
  },
  //  watch: {
  //   contentArr() {
  //     this.forceRerender()
  //   },
  // },
  data() {
    return { activeSillParent: 0, carouselKey: 1 };
  },
  methods: {
    passEvent(e) {
      this.$emit("handleChange", e);
    },
  },
};
</script>
<style lang="scss" scoped></style>










