<template>
  <div class="project-form">
    <slot name="project-form">
      <div class="row">
        <div class="col-lg-6 col-12 mb-4">
          <Input
            :label="$t('talentWizard.projectName')"
            :placeholder="$t('talentWizard.projectName')"
            :validationField="$v.project.name"
            :params="[
              'required',
              'alphaNumSpace',
              { maxLength: { number: '50' } },
            ]"
            isAstrik="true"
          />
        </div>

        <div class="col-lg-6 col-12 mb-4">
          <Select
            :label="$t('talentWizard.projectsType')"
            :placeholder="$t('talentWizard.selectProjectType')"
            :validationField="$v.project.project_type_id"
            isAstrik="true"
            :selectOptions="getProjectTypesOptions"
          />
        </div>
        <div class="col-12 mb-4">
          <Input
            :label="$t('talentWizard.projectLink')"
            placeholder="https://ProjectName.com"
            :params="[{ maxLength: { number: '2000' } }]"
            :validationField="$v.project.link"
            @handleChange="(e) => handleChange(e)"
          />
          <span v-if="!isValidUrl" class="error">{{$t('talentWizard.invalidLink')}} </span>
        </div>
        <span style="display: none">{{ validForm() }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import { deepUrlValidation } from "../../../../../utils/utils";
import Select from "../../../../../components/shared/formControls/select/select.vue";
import Input from "../../../../../components/shared/formControls/input/input.vue";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
const alphaNumSpace = helpers.regex("alphaNumSpace", /^[A-Za-z0-9 ]+$/);
import "./projectForm.scss";

export default {
  props: ["projectDetails"],
  components: { Input, Select },
  mounted() {
    this.getProjectTypes();
  },
  data() {
    return {
      project: this.projectDetails,
      isValidUrl: true,
    };
  },
  validations: {
    project: {
      id: {},
      name: {
        required,
        maxLength: maxLength(100),
        alphaNumSpace,
      },
      project_type_id: {
        required,
      },
      link: {
        maxLength: maxLength(2000),
      },
    },
  },
  computed: {
    getProjectTypesOptions() {
      return this.$store.getters["talentProfile/getProjectTypes"];
    },
  },

  methods: {
    getProjectTypes() {
      this.$store.dispatch("talentProfile/getProjectTypesRequest");
    },
    handleSubmitProjectForm() {
      if (this.project?.id == null || this.project?.id == undefined) {
        this.$store
          .dispatch("talentProfile/AddProjectRequest", this.project)
          .then(() => {
            this.$emit("handleCloseModal", false);
          });
      } else {
        this.$store
          .dispatch("talentProfile/updateProjectDetailsRequest", this.project)
          .then(() => {
            this.$emit("handleCloseModal", false);
          });
      }
    },
    validForm() {
      const isDisabled =
        this.project.name == "" ||
        this.project.project_type_id == "" ||
        this.project.project_type_id == null ||
        !this.isValidUrl;
      this.$emit("handleIsDisabled", isDisabled);
      return isDisabled;
    },
    handleChange(e) {
      if (e.target.value == "") {
        this.isValidUrl = true;
      } else {
       const enteredValue=(e.target.value.startsWith("https://")||e.target.value.startsWith("http://"))?e.target.value :`https://${e.target.value}`
        this.isValidUrl = !deepUrlValidation(enteredValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
