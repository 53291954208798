<template>
  <div class="education-form">
    <slot name="education-form">
      <div class="row">
        <div class="col-12 mb-4">
          <Input
            :label=" `${$t('talentWizard.school')} / ${$t('talentWizard.university')}`"
            :placeholder=" `${$t('talentWizard.school')} / ${$t('talentWizard.university')}`"
            :validationField="$v.education.university"
            :params="[
              'required',
              'alphaNumericWithSpecialChars',
              { maxLength: { number: '50' } },
            ]"
            isAstrik="true"
          />
        </div>
         <div class="col-lg-6 col-12 mb-4">
          <Input
            :label="$t('talentWizard.degree')"
            :placeholder="$t('talentWizard.degree')"
            :validationField="$v.education.degree"
            :params="[
              'alphaNumericWithSpecialChars',
              { maxLength: { number: '50' } },
            ]"
          />
        </div>
         <div class="col-lg-6 col-12 mb-4">
          <Input
            :label="$t('talentWizard.fieldOfStudy') "
            :placeholder="$t('talentWizard.fieldOfStudy')"
            :validationField="$v.education.study_field"
            :params="[
              'required',
              'alphaNumericWithSpecialChars',
              { maxLength: { number: '50' } },
            ]"
            isAstrik="true"
          />
        </div>
        <div class="col-12 mb-0 mb-md-4">
          <OneCheckBoxInput
            :ClassLabel="'control control--checkbox'"
            :labelText="$t('talentWizard.iAmUndergraduate')"
            :checked="education.currently"
            @input="inputChecked"
          />
        </div>
        <div class="col-sm-6 col-12 mb-0 mb-md-4">
          <div class="row">
            <div class="col-12">
              <label class="form-label required">
              {{$t('talentWizard.startDate')}}
              </label>
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <MonthsDropDown
                :className="'style-chooser'"
                @input="setStartMonth"
                :default="education.start_month"
              />
            </div>

            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <YearsDropDown
                :className="'style-chooser'"
                @input="setStartYear"
                :default="education.start_year"
              />
            </div>
          </div>
          <div class="row">
            <span style="color: #ff6f59">{{ futureDateError }}</span>
            <span style="color: #ff6f59" v-if="!futureDateError">{{
              startDateError
            }}</span>
          </div>
        </div>
        <div class="col-sm-6 col-12 mb-0 mb-md-4">
          <div class="row">
            <div class="col-12">
              <label class="form-label required">
              {{$t('talentWizard.endDate')}}
              </label>
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <MonthsDropDown
                :className="'style-chooser'"
                @input="setEndMonth"
                :isDisabled="education.currently"
                :default="education.end_month"
              />
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <YearsDropDown
                :className="'style-chooser'"
                @input="setEndYear"
                :isDisabled="education.currently"
                :default="education.end_year"
              />
            </div>
          </div>
          <div class="row">
            <span style="color: #ff6f59">{{ endDateError }}</span>
          </div>
        </div>
        <span style="display: none">{{ validForm() }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import {
  validateStartDate,
  validateEndDate,
} from "../../../../../functions/DateFunctions";
import { deepUrlValidation ,alphaNumericWithSpecialChars} from "../../../../../utils/utils";
import OneCheckBoxInput from "../../../../../components/shared/OneCheckBoxInput/OneCheckBoxInput";
import MonthsDropDown from "../../../../../components/shared/MonthsDropdown/MonthsDropdown";
import YearsDropDown from "../../../../../components/shared/YearsDropdown/YearsDropdown";
import Input from "../../../../../components/shared/formControls/input/input.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import "./talentEducationForm.scss";

export default {
  props: [
    "educationDetails",
    "educationEndDateError",
    "educationFutureDateError",
    "educationStartDateError",
  ],
  components: {
    Input,
    OneCheckBoxInput,
    MonthsDropDown,
    YearsDropDown,
  },
  data() {
    return {
      startDateError: this.educationStartDateError,
      futureDateError: this.educationFutureDateError,
      endDateError: this.educationeEndDateError,
      education: this.educationDetails,
      isValidUrl: true,
    };
  },
 validations: {
    education: {
      university: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars
      },
      study_field: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars
      },
      degree: {
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars
      }
    }
  },
  methods: {
    inputChecked(value) {
      this.education.currently = value;
      if (value == 1) {
        this.startDateError = "";
        this.endDateError = "";
        this.education.end_month = "";
        this.education.end_year = "";
      }
    },
    setStartMonth(value) {
      this.education.start_month = value;
      this.callvalidateStartDate();
    },
    setEndMonth(value) {
      this.education.end_month = value;
      this.callvalidateEndDate();
    },
    setStartYear(value) {
      this.education.start_year = value;
      this.callvalidateStartDate();
    },
    setEndYear(value) {
      this.education.end_year = value;
      this.callvalidateEndDate();
    },
    callvalidateStartDate() {
      let res = validateStartDate(
        this.education.start_month,
        this.education.start_year,
        this.education.end_month,
        this.education.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
      this.futureDateError =  res.futureDateError ?this.$t(`validation.${[res.futureDateError]}`) : ''
    },
    callvalidateEndDate() {
      let res = validateEndDate(
        this.education.start_month,
        this.education.start_year,
        this.education.end_month,
        this.education.end_year
      );
       this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
    },
    handleSubmitEducationForm() {
      if (this.education?.id == null || this.education?.id == undefined) {
        this.$store
          .dispatch("talentProfile/addEducationRequest", this.education)
          .then(() => {
            this.$emit("handleCloseModal", false);
            this.resetFormData();
          });
      } else {
        this.$store
          .dispatch("talentProfile/updateEducationRequest", this.education)
          .then(() => {
            this.$emit("handleCloseModal", false);
          });
      }
    },
 handleUrlChange(e) {
      if (e.target.value == "") {
        this.isValidUrl = true;
      } else {
        this.isValidUrl = !deepUrlValidation(e.target.value);
      }
    },
    validForm() {
      const isDisabled =(  this.$v.$invalid ||
        !!this.startDateError ||
        !!this.futureDateError ||
        !!this.endDateError ||
        !this.education.start_year ||
        !this.education.start_month ||
        (!this.education.currently &&
          (!this.education.end_year || !this.education.end_month)))
        
      this.$emit("handleIsDisabled", isDisabled);
      return isDisabled;
    },
  },
};
</script>

<style lang="scss" scoped></style>
