<template>
  <div :class="className">
    <label :class="ClassLabel">
     <span> {{ labelText }}</span>
      <input
        type="checkbox"
        :checked="checked"
        :value="value"
        @input="changeValue(); $emit('input', value);"
      />
      <div class="control__indicator"></div>
    </label>
  </div>
</template>

<script>
export default {
  props: ["className", "ClassLabel", "labelText", "checked"],
  data() {
    return {
      value: this.checked
    };
  },
  methods: {
    changeValue() {
      this.value = Number(!this.value);
    }
  },
  watch: {
    checked: function() {
      this.value = this.checked;
    }
  }
};
</script>
