import { render, staticRenderFns } from "./languagesTab.vue?vue&type=template&id=885d8682&scoped=true"
import script from "./languagesTab.vue?vue&type=script&lang=js"
export * from "./languagesTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885d8682",
  null
  
)

export default component.exports