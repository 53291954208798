<template>
  <div class="search-input">
    <div class="mb-3 input-group flex-nowrap search-skills">
      <input
        type="text"
        class="form-control"
        :placeholder="placeholder"
        @input="(e) => handleChange(e)"
      />
      <span class="input-group-text" id="addon-wrapping"
        ><i class="bi bi-search"></i
      ></span>
    </div>
  </div>
</template>
<script>
import "./searchInput.scss";
export default {
  props: ["placeholder", "searchInArr"],
  methods: {
    handleChange(e) {
      let searchResult = this.searchInArr?.filter((s) =>
        s.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.$emit("searchInputEvent", searchResult);
    },
  },
};
</script>
<style lang="scss" scoped>

.search-input {
  .input-group-text {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.form-control {
  border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
}
</style>
