<template>
  <div class="salary-wrapper">
    <div
      class="mb-2 row skill-14 align-items-center"
      v-for="(item, i) in handleSalaryDataView"
      :key="i"
    >
      <div class="col-7 padding-r text-start d-flex align-items-center">
        <h4 class="mb-0 flex-grow-1 d-flex">
          <img src="../../../../../assets/images/ic_salary.svg" class="me-2" />
          <span>
            {{ $t(`signUp.${item.label}`) }}
          </span>
        </h4>
      </div>
      <div
        v-if="is_edit[item.salaryType]"
        class="px-0 col-5 d-flex justify-content-end editSalaryWrapper"
      >
        <Input
          type="number"
          :placeholder="$t('talent.enterSalary')"
          :validationField="$v.salaryInput[item.salaryKey]"
          errMsgStyle="errMsgStyle"
          wrapperStyle="h-100"
          isExternalErrMsg="true"
        />
        <div
          class="mb-0 ok-button"
          @click.prevent.stop="handleSubmitSalary(item.salaryType, false, item.salaryKey)"
        >
         {{ $t('talent.ok') }}
        </div>
      </div>
      <div class="px-0 col text-end text-grey" v-if="!is_edit[item.salaryType]">
        <div
          class="mb-0 font-size-14 d-flex align-items-center justify-content-end"
        >
          <div>
            <span v-if="item.salaryValue">{{ item.salaryValue }} $</span>
            <span v-if="!item.salaryValue">
              {{ $t('talent.incomplete') }}
            </span>
          </div>
          <div>
            <i
              class="edit-icon"
              @click.prevent.stop="handleEditView(item.salaryType, true)"
            >
              <EditIcon />
            </i>
          </div>
        </div>
      </div>
      <p class="pt-1 mb-0 col-12 errMsgStyle">
        {{ displyErrMsg(salaryInput[item.salaryKey]) }}
      </p>
    </div>
  </div>
</template>

<script>
import "./salary.scss";
import EditIcon from "../icons/editIcon.vue";
import { between, integer } from "vuelidate/lib/validators";
import Input from "../../../../../components/shared/formControls/input/input.vue";
export default {
  props: ["full_time_salary", "part_time_salary"],
  components: { EditIcon, Input },

  data() {
    return {
      errorMessage: "",
      salaryInput: {
        full_time_salary: this.full_time_salary,
        part_time_salary: this.part_time_salary,
      },
      is_edit: {
        fullTimeSalary: false,
        partTimeSalary: false,
      },
    };
  },
  computed: {
    console: () => console,
    handleSalaryDataView() {
      const salaryDataList = [
        {
          label: "fullTimeSalary",
          salaryType: "fullTimeSalary",
          salaryKey: "full_time_salary",
          salaryValue: this.full_time_salary,
        },
        {
          label: "partTimeSalary",
          salaryType: "partTimeSalary",
          salaryKey: "part_time_salary",
          salaryValue: this.part_time_salary,
        },
      ];
      return salaryDataList;
    },
  },
  validations: {
    salaryInput: {
      full_time_salary: {
        integer,
        between: between(1, 1000000),
      },
      part_time_salary: {
        integer,
        between: between(1, 1000000),
      },
    },
  },
  methods: {
    displyErrMsg(salaryValue) {
      if (salaryValue) {
        if (salaryValue <= 0) {
          return "Value should be greater than 1";
        } else if (JSON.stringify(salaryValue).includes(".")) {
          return "Only Integer values";
        } else if (salaryValue > 1000000) {
          return "Max value 1000000";
        } else {
          return "";
        }
      }
    },
    handleEditView(salaryType, isEdit) {
      console.log('handleEditView',salaryType, isEdit );
      this.is_edit[salaryType] = isEdit;
    },
    handleSubmitSalary(salaryType, isEdit, salaryValue) {
      let payload = {};
      if (!this.$v.salaryInput[salaryValue].$invalid) {
        this.handleEditView(salaryType, isEdit);
        payload.salary = this.salaryInput.full_time_salary
          ? parseInt(this.salaryInput.full_time_salary)
          : "";
        payload.part_time_salary = this.salaryInput.part_time_salary
          ? parseInt(this.salaryInput.part_time_salary)
          : "";
        this.$store.dispatch("talentProfile/updateProfileSalary", payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
